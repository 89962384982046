<template>
    <el-main>
        <div class="order-info-top">
            <div class="title">提现单号:{{ topInfo.withdraw_no }}</div>
            <el-tag size="small" style="margin-left: 20px">{{ topInfo.status == 1 ? '已审核' : topInfo.status == 2 ? '已打款' :
                topInfo.status == -1 ? '已驳回' : '待审核' }}</el-tag>
        </div>
        <template>
            <div>
                <span>1.提现状态</span>
                <el-divider></el-divider>
            </div>
        </template>
        <div class="content">
            <div class="handle-progress">
                <div class="item" style="background: #ddddfd">
                    <div class="num" style="background: #409eff; color: #fff">1</div>
                    <p class="handle-text" style="color: #409eff">申请时间</p>
                    <p class="handle-time">{{ getDateformat(topInfo.create_time) }}</p>
                </div>
                <div class="line"></div>
                <div class="item" style="background: #ddddfd">
                    <div class="num" style="background: #409eff; color: #fff">2</div>
                    <p class="handle-text" style="color: #409eff">审核时间</p>
                    <p class="handle-time">{{ getDateformat(topInfo.update_time) }}</p>
                </div>
                <div class="line"></div>
                <div class="item" style="background: #ddddfd">
                    <div class="num" style="background: #409eff; color: #fff">3</div>
                    <p class="handle-text" style="color: #409eff">打款时间</p>
                    <p class="handle-time">{{ getDateformat(topInfo.voucher_time) }}</p>
                </div>
            </div>
        </div>

        <template>
            <div>
                <span>2.提现信息</span>
                <el-divider></el-divider>
            </div>
        </template>
        <div class="contentmian">
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <span>申请金额</span>
                </div>
                <div class="text item">
                    <div style="font-size: 24px; text-align: center; margin-bottom: 20px; font-weight: bold">
                        {{ topInfo.property_settlement_money }}元</div>
                </div>
                <div class="text item">
                    <div style="font-size: 16px; text-align: center">订单金额:{{ topInfo.order_total_price }}</div>
                </div>
            </el-card>
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <span>申请的服务订单总额</span>
                </div>
                <div class="text item">
                    <div style="font-size: 24px; text-align: center; margin-bottom: 20px; font-weight: bold">
                        {{ topInfo.order_total_price }}元</div>
                </div>
                <div class="text item">
                    <div style="font-size: 16px; text-align: center">订单个数:{{ topInfo.order_num }}个</div>
                </div>
            </el-card>
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <span>平台佣金</span>
                </div>
                <div class="text item">
                    <div style="font-size: 24px; text-align: center; margin-bottom: 20px; font-weight: bold">
                        {{ topInfo.platform_rate }}%</div>
                </div>
                <div class="text item">
                    <div style="font-size: 16px; text-align: center">抽成金额:{{ topInfo.property_settlement_money *
                        topInfo.platform_rate / 100 }}元</div>
                </div>
            </el-card>
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <span>结算比例</span>
                </div>
                <div class="text item">
                    <div style="font-size: 24px; text-align: center; margin-bottom: 20px; font-weight: bold">
                        {{ topInfo.rate }}%</div>
                </div>
                <div class="text item">
                    <div style="font-size: 16px; text-align: center">抽成金额:{{ topInfo.property_settlement_money *
                        (100 - topInfo.rate) / 100 }}元</div>
                </div>
            </el-card>
        </div>

        <template>
            <div>
                <span>3.流水信息</span>
                <el-divider></el-divider>
            </div>
        </template>
        <!-- 表单 -->
        <el-form class="el-form-search" label-width="140px">
            <el-form-item label="">
                <el-input size="small" v-model="form.order_sn" placeholder="请输入订单单号"></el-input>
            </el-form-item>
            <el-form-item label=" " label-width="25px">
                <el-button type="primary" size="small" @click="search">搜索</el-button>
                <el-button style="border: 1px solid #409EFF; background-color: #fff" size="small"
                    @click="exportData">导出</el-button>
                <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="list" row-key="id" default-expand-all :header-cell-style="{ 'background-color': '#F8F9FA' }">
            <template slot="empty">
                <No />
            </template>

            <el-table-column align="center">
                <template #header>
                    <el-checkbox v-model="allCheck" @change="allCheckChange" label="全选"></el-checkbox>
                </template>
                <template v-slot="{ row }">
                    <el-checkbox @change="checkChange(row)" v-model="row.checkFlag"></el-checkbox>
                </template>
            </el-table-column>

            <el-table-column prop="order_sn" label="订单号" align="center"></el-table-column>
            <el-table-column prop="community_name" label="小区名称" align="center"></el-table-column>
            <el-table-column label="用户身份" align="center">
                <template v-slot="{ row }">
                    {{ row.is_vip ? 'Vip' : '非Vip' }}
                </template>
            </el-table-column>
            <el-table-column prop="total_price" label="订单金额" align="center"></el-table-column>
            <el-table-column prop="settlement_price" label="物业结算金额" align="center"></el-table-column>
            <el-table-column label="结算状态" align="center">
                <template v-slot="{ row }">
                    {{ row.status == 1 ? '待对账' : row.status == 2 ? '已对账' : row.status == 3 ? '平台对账同意' : row.status == 4 ?
                        '平台对账拒绝' : row.status == 5 ? '已打款' : '--' }}
                </template>
            </el-table-column>
            <el-table-column label="结算单据" align="center">
                <template v-slot="{ row }">
                    <el-button type="text" @click="getSign(row)">查看</el-button>
                </template>
            </el-table-column>
            <el-table-column label="物业对账单" align="center">
                <template v-slot="{ row }">
                    <el-button type="text" @click="getLogDetail(row)">查看</el-button>
                </template>
            </el-table-column>
            <el-table-column label="订单时间" align="center">
                <template v-slot="{ row }">
                    <span>{{ getDateformat(row.create_time) }}</span>
                </template>
            </el-table-column>
        </el-table>
        <el-button type="text" @click="upStatus('agree')">全部审核通过</el-button>
        <el-button type="text" @click="upStatus('rejectall')">全部审核驳回</el-button>
        <el-button type="text" @click="upStatus('rejectpart')">部分审核驳回</el-button>
        <Paging :total="total_number" :page="form.page" :pageNum="form.rows" @updatePageNum="updateServiceData"></Paging>

        <el-dialog title="驳回" :visible.sync="reject" width="30%" :before-close="handleClose">
            <el-input type="textarea" :rows="6" placeholder="请输入内容" v-model="textarea"></el-input>

            <span slot="footer" class="dialog-footer">
                <el-button @click="reject = false">取 消</el-button>
                <el-button type="primary" @click="onUpStatusSubmit">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog v-if="info.order" title="对账" :visible.sync="dialogVisible" width="40%" :before-close="handleClose">
            <div>
                <el-main class="contentBox">
                    <div class="user-info">
                        <div class="replenish">
                            <!-- <div class="imageSpan"> -->

                            <!-- <el-image style="width: 60px; height: 60px" src=""></el-image> -->
                            <!-- </div> -->
                            <div>
                                <span>服务名称：</span>
                                {{ info.order.service_name }}
                            </div>
                            <div>
                                <span>服务订单号：</span>
                                {{ info.order.order_sn }}
                            </div>
                            <div class="flexWrap">
                                <div>
                                    <span>提交时间：</span>
                                    {{ getDateformat(info.order.create_time) }}
                                </div>
                                <div>
                                    <span>尾款时间：</span>
                                    {{ getDateformat(info.order.final_pay_time) }}
                                </div>
                                <div style="display: flex">
                                    <span>下单联系人：</span>
                                    {{ info.order.name }}
                                    <div>{{ info.order.is_vip ? 'Vip' : '非Vip' }}</div>
                                </div>
                                <div>
                                    <span>技工姓名：</span>
                                    {{ info.artisan_name }}
                                </div>
                                <div>
                                    <span>下单人联系电话：</span>
                                    {{ info.order.mobile }}
                                </div>
                                <div>
                                    <span>技工联系电话：</span>
                                    {{ info.artisan_mobile }}
                                </div>
                            </div>

                            <div style="color: blue; margin-bottom: 10px">
                                <span>订单支付金额：</span>
                                {{ info.order.total_price }}
                            </div>
                            <div style="color: blue">
                                <span>人工工时费：</span>
                                {{ (info.order.mechanic_time - info.order.vip_no_hour_cost) * info.order.one_hour_cost }}
                            </div>
                            <div>
                                <span>开始时间：</span>
                                {{ getDateformat(info.order.mechanic_start_time) }}
                            </div>
                            <div style="display: flex; justify-content: space-between; align-items: center">
                                <div>
                                    <div style="padding-bottom: 10px">
                                        <span>结束时间：</span>
                                        {{ getDateformat(info.order.mechanic_end_time) }}
                                    </div>
                                    <div>
                                        <span>工时费单价：</span>
                                        ￥ {{ info.order.one_hour_cost }}元/时

                                        <div style="margin-left: 100px; padding: 10px 0">
                                            <span>工时：</span>
                                            {{ info.order.mechanic_time }}小时
                                        </div>
                                        <div style="margin-left: 100px; padding-bottom: 10px">
                                            <span>VIP免费工时：</span>
                                            {{ info.order.vip_no_hour_cost }}小时
                                        </div>
                                    </div>
                                </div>
                                <div style="font-size: 40px; color: red">￥{{ info.hourly_price }}</div>
                            </div>

                            <div style="display: flex; justify-content: space-between; align-items: center">
                                <div style="color: blue; width: 50%">
                                    <span>维修材料费：</span>
                                    ￥{{ info.order.mechanic_material_price }}
                                </div>
                                <div style="width: 50%; display: flex; justify-content: space-between; align-items: center">
                                    <span>工时对账金额：</span>
                                    <input type="text" :value="info.hourly_price"
                                        style="width: 100px; height: 30px; border: 1px solid #ccc; padding: 0 10px; text-align: right" />
                                </div>
                            </div>
                        </div>
                        <div style="display: flex; justify-content: space-between; align-items: center">
                            <div style="padding-bottom: 10px; width: 50%">
                                <span>维修材料清单：</span>
                            </div>
                            <div style="padding-bottom: 10px; width: 50%">
                                <span>物业对账金额：</span>
                            </div>
                        </div>
                        <div style="display: flex; justify-content: space-between; align-items: center">
                            <div style="padding-bottom: 10px; width: 50%">
                                <span></span>
                            </div>
                            <div style="padding-bottom: 10px; width: 50%; display: flex; align-items: center">
                                <span style="display: block; width: 40%">材料名称</span>
                                <span style="display: block; width: 40%">材料单价</span>
                                <span style="display: block; width: 30%">材料数量</span>
                            </div>
                        </div>
                        <div style="display: flex; padding-bottom: 10px">
                            <div style="display: flex; flex-direction: column; width: 50%">
                                <div style="display: flex; padding-bottom: 10px; width: 100%"
                                    v-for="(item, index) in mechanic_material_te" :key="index">
                                    <div
                                        style="display: flex; justify-content: space-between; width: 200px; margin-right: 50px">
                                        <span>{{ item.name }}：</span>
                                        ￥{{ item.price }}
                                    </div>
                                    <div style="display: flex; justify-content: space-between; width: 100px">
                                        <span>数量：</span>
                                        {{ item.num }}
                                    </div>
                                    <div style="width: 70px; text-align: center">→</div>
                                </div>
                            </div>

                            <div style="display: flex; flex-direction: column; width: 50%">
                                <div style="width: 100%; display: flex; justify-content: space-between; align-items: center"
                                    v-for="(value, index) in form.mechanic_material" :key="value.id">
                                    <div style="width: 26%"><input type="text" v-model="value.name"
                                            style="width: 100%; height: 30px; border: 1px solid #ccc; padding: 0 10px" />
                                    </div>
                                    <div style="width: 26%"><input type="text" v-model="value.num"
                                            style="width: 100%; height: 30px; border: 1px solid #ccc; padding: 0 10px" />
                                    </div>
                                    <div style="width: 20%"><input type="text" v-model="value.price"
                                            style="width: 100%; height: 30px; border: 1px solid #ccc; padding: 0 10px" />
                                    </div>
                                    <div style="color: blue; width: 28px; margin-left: 6px" v-if="index >= 1"
                                        @click="delService(index)">删除</div>
                                    <div style="color: blue; width: 28px; margin-left: 6px" v-else></div>
                                    <br />
                                </div>
                            </div>
                        </div>
                        <div
                            style="display: flex; justify-content: space-between; align-items: center; padding-bottom: 30px">
                            <div style="padding-bottom: 10px; width: 50%">
                                <span></span>
                            </div>
                            <div style="color: blue; width: 50%" @click="addService">+添加其他材料项目</div>
                        </div>
                        <div style="display: flex; justify-content: space-between; align-items: center">
                            <div style="padding-bottom: 10px; width: 50%">
                                <span></span>
                            </div>
                            <div
                                style="width: 50%; display: flex; justify-content: space-between; align-items: center; padding-top: 30px; border-top: 1px solid #000">
                                <el-button type="primary" size="small">材料总计</el-button>
                                <span style="color: red; font-size: 28px">￥{{ info.mechanic_material_price }}</span>
                            </div>
                        </div>
                        <div style="display: flex; justify-content: space-between; align-items: center">
                            <div style="padding-bottom: 10px; width: 50%">
                                <span></span>
                            </div>
                            <div
                                style="width: 50%; display: flex; justify-content: space-between; align-items: center; padding-top: 30px">
                                <el-button type="info" size="small">总结算金额</el-button>
                                <span style="color: red; font-size: 28px">￥{{ info.settlement_price }}</span>
                            </div>
                        </div>
                    </div>
                </el-main>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="onSubmit">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog v-if="info.order" title="结算单" :visible.sync="dialogSign" width="30%">

            <div id="screenshot-box">
                <!-- 定频立式空调外室机维修 -->
                <div class="orderinfo">
                    <div class="til">{{ info.order.service_name }}</div>
                    <ul>
                        <li>
                            <span style="color: #999999;">订单编号：</span>
                            <span>{{ info.order.order_sn }}</span>
                        </li>
                        <li>
                            <span style="color: #999999;">维修时间：</span>
                            <span>{{ getDateformat(info.order.order_time) }}</span>
                        </li>
                        <li>
                            <span style="color: #999999;">下单联系人：</span>
                            <span>{{ info.order.name }}</span>
                            <el-image v-if="info.order.is_vip"
                                src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/community/vipLogo.png" mode=""
                                style="width: 31px;height: 18px;margin-left: 20px;"></el-image>
                        </li>
                        <li>
                            <span style="color: #999999;">联系电话：</span>
                            <span>{{ info.order.mobile }}</span>
                        </li>
                        <li v-if="info.artisan_name">
                            <span style="color: #999999;">技工姓名：</span>
                            <span>{{ info.artisan_name }}</span>
                        </li>
                        <li v-if="info.artisan_mobile">
                            <span style="color: #999999;">技工电话：</span>
                            <span>{{ info.artisan_mobile }}</span>
                        </li>
                        <li>
                            <span style="color: #999999;">付款日期：</span>
                            <span>{{ info.order.deposit_pay_time ? getDateformat(info.order.deposit_pay_time) : '' }}</span>
                        </li>

                    </ul>
                </div>

                <!-- 人工费用 -->
                <div class="orderinfo">
                    <!-- 人工费用 -->
                    <div class="laborCost">
                        <div class="laborCostTil">
                            <div class="til" style="margin-bottom: 16px;">人工费用
                            </div>
                            <div class="til" style="margin-bottom: 16px;color:#FF982E"
                                v-if="info.order.mechanic_time <= info.order.vip_no_hour_cost && info.order.is_vip">
                                免费</div>
                            <div class="til" style="margin-bottom: 16px;" v-if="info.is_vip">
                                ￥{{ info.order.one_hour_cost * (info.order.mechanic_time - info.order.vip_no_hour_cost) }}</div>
                            <div class="til" style="margin-bottom: 16px;" v-else>
                                ￥{{ info.order.one_hour_cost * (info.order.mechanic_time) }}</div>
                        </div>
                        <ul>
                            <li style="margin-top: 0;justify-content: space-between;display: flex;">
                                <div style="width: 454px;display: flex;">
                                    <span style="width: 140px;">总计工时：</span>
                                    <span style="width: 180px;">{{ info.order.mechanic_time }}小时</span>
                                </div>
                                <span class="vipMoney" v-if="info.is_vip">VIP免费维修1小时</span>
                            </li>
                            <li style="margin-top: 24px;display: flex;">
                                <span style="width: 140px;">开始时间：</span>
                                <span>{{ getDateformat(info.order.mechanic_start_time) }}</span>
                            </li>
                            <li style="display: flex;">
                                <span style="width: 140px;">结束时间：</span>
                                <span>{{ getDateformat(info.order.mechanic_end_time) }}</span>
                            </li>
                        </ul>
                    </div>
                    <!-- 材料清单总费用 -->
                    <div class="laborCost" style="margin-top: 36px;">
                        <div class="laborCostTil" v-if="info.order.mechanic_material">
                            <div class="til" style="margin-bottom: 16px;">材料清单总费用: </div>
                            <div class="til" style="margin-bottom: 16px;" v-if="info.order.mechanic_material_d">
                                ￥{{ info.order.mechanic_material_d.reduce((prev, item) => prev + item.price * item.num, 0) }}
                            </div>
                        </div>
                        <ul class="materialCostUl" v-if="info.order.mechanic_material_d">
                            <li v-for="item in info.order.mechanic_material_d" :key="item.id">
                                <span style="span-align: left;">{{ item.name }}</span>
                                <span>x{{ item.num }}</span>
                                <span style="span-align: right;">￥{{ item.price }}</span>
                            </li>
                        </ul>
                        <div class="materialCostPrice">
                            <div>
                                <span>订单总价：</span>
                                <span>￥{{ info.order.total_price }}</span>
                            </div>
                            <div v-if="info.order.fianl_reduce_price * 1">
                                <span></span>
                                <span style="color: #FF982E;">优惠：-￥{{ info.order.fianl_reduce_price }}</span>
                            </div>
                            <div>
                                <span>已付定金：</span>
                                <span>￥{{ info.order.deposit_price }}</span>
                            </div>
                            <div style="margin-bottom: 0;height: 30px;">
                                <span>应付尾款：</span>
                                <div style="color: #F82222;font-size: 30px;font-weight: bold;"
                                    v-if="info.order.fianl_reduce_price">
                                    ￥{{ info.order.total_price - info.order.fianl_reduce_price - info.order.deposit_price }}
                                </div>
                                <div style="color: #F82222;font-size: 30px;font-weight: bold;" v-else>
                                    ￥{{ info.order.total_price - info.order.deposit_price }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="sign">
                        <span style="width: 114px;">签字人：</span>
                        <el-image :src="info.order.sign" mode="" style="width: 100px;height: 100px;"></el-image>
                    </div>
                </div>
            </div>

        </el-dialog>
    </el-main>
</template>
<script>
import config from '@/util/config';
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
export default {
    components: {
        Paging,
    },
    data () {
        return {
            ids: [],
            list: [],
            topInfo: [],
            dialogSign: false,
            dialogVisible: false,
            info: {
                order: []
            },
            allCheck: false,
            chooseList: [],
            total_number: 0,
            reject: false,
            textarea: '',
            mechanic_material_te: [],
            form: {
                page: 1,
                rows: 10,
                order_sn: '',
                mechanic_material: [
                ],
            },

            status: 2,
        };
    },
    created () {
        this.getList();
        this.getDetail();
    },
    methods: {
        getDateformat,
        // 添加材料
        addService () {
            this.form.mechanic_material.push({
                name: '',
                num: '',
                price: '',
            });

            console.log(this.form.mechanic_material);
        },
        // 删除材料
        delService (id) {
            let index = this.form.mechanic_material.findIndex(item => item.id == id);
            this.form.mechanic_material.splice(index, 1);
        },
        search () {
            this.form.page = 1;

            this.getList();
        },
        clearSearch () {
            this.form.page = 1;
            this.form.rows = 10;
            this.form.order_sn = '';
            this.getList();
        },
        getDetail () {
            this.$axios.post(this.$api.repair.finance.propertyWithdrawDetailTop, { id: this.$route.query.id }).then(res => {
                if (res.code == 0) {
                    this.topInfo = res.result;
                }
            });
        },
        getList () {
            let data = this.form
            data.id = this.$route.query.id
            this.$axios.post(this.$api.repair.finance.propertyWithdrawDetail, data).then(res => {
                if (res.code == 0) {
                    let list = res.result.list;
                    this.total_number = res.result.total;
                    this.list = list;
                    this.list.map(item => {
                        this.$set(item, 'checkFlag', false);
                    });
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        exportData () {
            let data = {
                id: this.$route.query.id,
                is_export: 1
            };
            this.$axios.post(this.$api.repair.finance.propertyWithdrawDetail, data).then(res => {
                if (res.code == 0) {
                    let path = res.result;
                    let a = document.createElement('a');
                    a.href = path;
                    a.id = 'download';
                    document.body.appendChild(a);
                    a.click();
                    let aDom = document.getElementById('download');
                    document.body.removeChild(aDom);
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        addMaterial () {
            this.form.mechanic_material.push({
                name: '',
                num: 0,
                price: '',
            });
        },
        onSubmit () {
            this.dialogVisible = false;
            console.log(this.form);
        },
        getLogDetail (item) {
            this.dialogVisible = true;
            this.$axios.post(this.$api.repair.finance.getLogDetail, { id: item.id }).then(res => {
                if (res.code == 0) {
                    this.info = res.result;
                    this.mechanic_material_te = JSON.parse(JSON.stringify(this.info.order.mechanic_material_d));
                    //this.form.mechanic_material = this.info.order.mechanic_material_d;
                    this.form.mechanic_material = this.info.mechanic_material_d;
                    console.log(111, this.form.mechanic_material);
                    console.log(this.info);
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getSign (item) {
            this.dialogSign = true;
            this.$axios.post(this.$api.repair.finance.getLogDetail, { id: item.id }).then(res => {
                if (res.code == 0) {
                    this.info = res.result;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },

        upStatus (type) {
            console.log(this.chooseList);
            if (this.chooseList.length == 0) {
                this.$message('请先选择');
                return false;
            }
            if (type == 'rejectpart') {
                this.reject = true;
            }
            if (type == 'agree') {
                this.$confirm('确定全部审核通过, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                })
                    .then(() => {
                        let data = {
                            ids: this.chooseList,
                            status: 3,
                        };

                        this.$axios.post(this.$api.repair.finance.checkStatus, data).then(res => {
                            if (res.code == 0) {
                                console.log(11);
                            } else {
                                this.$message.error(res.msg);
                            }
                        });
                    })
                    .catch(() => { });
            }
            if (type == 'rejectall') {
                this.reject = true;
            }
        },
        onUpStatusSubmit () {
            let data = {
                ids: this.chooseList,
                status: 4,
                reason: this.textarea,
            };
            this.$axios.post(this.$api.repair.finance.checkStatus, data).then(res => {
                if (res.code == 0) {
                    this.reject = false;
                    this.getList()
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        // 操作全选
        allCheckChange () {
            if (this.allCheck) {
                this.list.map(item => {
                    item.checkFlag = true;
                    this.chooseList.push(item.id);
                });
            } else {
                this.list.map(item => {
                    item.checkFlag = false;
                    this.chooseList = [];
                });
            }
        },
        checkChange (data) {
            if (!data.checkFlag) {
                this.allCheck = false;
                let index = this.chooseList.findIndex(item => item == data.id);
                this.chooseList.splice(index, 1);
            } else {
                this.chooseList.push(data.id);
                if (this.chooseList.length === this.list.length) this.allCheck = true;
            }
        },
    },
};
</script>

<style lang="less" scoped>
.flexWrap {
    display: flex;
    flex-wrap: wrap;
}

.replenish>div {
    //   width: 50%;
    padding-bottom: 10px;
}

.flexWrap>div {
    width: 50%;
    padding-bottom: 10px;
}

.replenish>div>span {
    display: inline-block;
    //   margin-left: 20px;
}

.order-info-top {
    display: flex;
}

.title {
    font-size: 14px;
    line-height: 22px;
    padding-bottom: 10px;
}

.content {
    .order-num {
        display: flex;
        height: 47px;
        background: #f0f0f5;
        align-items: center;
        padding-left: 24px;

        li {
            font-size: 14px;
            margin-right: 40px;

            span {
                font-weight: 400;
                color: #17171a;
            }
        }
    }

    .handle-progress {
        display: flex;
        justify-content: center;
        height: 190px;
        align-items: center;

        .line {
            height: 4px;
            width: 120px;
            background: #f0f0f5;
        }

        .item {
            background: #fff;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            .num {
                width: 32px;
                height: 32px;
                line-height: 32px;
                text-align: center;
                background: #c8c8cc;
                border-radius: 50%;
                color: #ffffff;
                font-size: 14px;
                font-weight: bold;
            }

            .handle-text {
                width: 100px;
                text-align: center;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: -25px;
                font-size: 14px;
                font-weight: bold;
                color: #969699;
            }

            .handle-time {
                width: 120px;
                position: absolute;
                left: 50%;
                text-align: center;
                transform: translateX(-50%);
                bottom: -25px;
                font-size: 12px;
                font-weight: 400;
                color: #969699;
            }
        }
    }

    .handle-status {
        padding: 30px 0;
        background: #f0f0f5;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .status {
            font-size: 16px;
            font-weight: bold;
        }

        p {
            font-size: 14px;
            font-weight: 400;
            color: #969699;
        }

        .btn {
            margin-top: 18px;
        }

        .order-actions {
            margin-top: 10px;
        }
    }
}

.contentmian {
    display: flex;
    justify-content: space-between;
    padding-bottom: 24px;
}

.text {
    font-size: 14px;
}

//.item {
//}

.clearfix {
    display: flex;
    justify-content: center;
    align-items: center;
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: '';
}

.clearfix:after {
    clear: both;
}

.box-card {
    width: 20%;
    margin-right: 10px;
    border-top: 6px solid #ccc;
}

.el-form-search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.orderinfo {
    background-color: #fff;
    border-radius: 16px 16px 16px 16px;
    margin-bottom: 26px;

    li {
        display: flex;
        align-items: center;
        margin-top: 18px;
        color: #333333;
        font-size: 18px;
    }

    .laborCost span:nth-child(2) {
        font-weight: 400;
    }

    .laborCost {
        .laborCostTil {
            display: flex;
            justify-content: space-between;
        }

        ul {
            background-color: #f3f5f3;
            padding: 26px 20px;
            border-radius: 16px;

            li {
                margin-top: 12px;
            }
        }
    }

    .materialCostUl {
        li {
            display: flex;
            justify-content: space-between;
        }
    }

    .materialCostPrice {
        display: flex;
        flex-direction: column;
        color: #333333;
        font-size: 18px;
        margin-top: 46px;

        div {
            display: flex;
            justify-content: space-between;
            margin-bottom: 22px;
        }
    }
}

.orderinfo span:nth-child(2) {
    font-weight: bold;
}

.orderinfo span:nth-child(1) {
    display: inline-block;
    width: 200px;
}

.materialCostUl span:nth-child(1) {
    width: 200px;
}

.til {
    font-size: 20px;
    font-weight: bold;
    color: #333333;
}

.sign {
    margin: 47px 0px;
}

.sign span:nth-child(1) {
    font-size: 18px;
}

.sign span:nth-child(2) {
    font-size: 2px;
    font-weight: bold;
}

.vipMoney {
    width: 300px !important;
    span-align: right;
    color: #ff982e;
}</style>